import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'
import { LoadMore } from '../components/icons.js'

class PostTemplate extends Component {

  componentDidMount() {
    document.querySelector('.header__logo span').innerHTML = 'Journal'
  }

  render() {
    let page = this.props.data.wordpressPost
    let author = page.acf.author ? page.acf.author : 'EM Advisory'
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';
    let ogImage = page.yoast_meta.find(x => x.property === 'og:image') ? page.yoast_meta.find(x => x.property === 'og:image').content.source_url : '';
    
    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={page.slug} description={metaDescription} ogImage={ogImage} />
        <article className='article article--wrapper'>
          <div className='article__inner'>
            <section className='article__header'>
              <Fade bottom distance={'40px'}>
                <h1>{he.decode(page.title)}</h1>
                <div>By {author}</div>
              </Fade>
              <Fade bottom distance={'40px'}>
                { page.categories.length && 
                  <ul>
                    { page.categories.map((cat, i) => {
                      return (
                        <li data-category={cat.slug} key={i}>
                          { i > 0 && ', ' }
                          { cat.name }
                        </li>
                      )
                    }) }
                  </ul>
                }
              </Fade>
            </section>
            { page.acf.post_blocks_post && page.acf.post_blocks_post.map((el, i) => {
              return RenderBlock(el.__typename, el, i)
            }) }
          </div>
        </article>
        <section className='pagination'>
          <ul>
            <li><Link to='/journal'><LoadMore colour={'#000'} />Back</Link></li>
            <li><Link to='/contact'>Contact<LoadMore colour={'#000'} /></Link></li>
          </ul>
        </section>
      </>
    )
  }
}

export default PostTemplate

export const postQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      slug
      categories {
        name
        slug
      }
      yoast_title
      yoast_meta {  
       name
       content {
         source_url
       }
       property
      }
      acf {
        author
        post_blocks_post {
          __typename
          ... on WordPressAcf_image {
            image {
              source_url
              ...original
            }
            caption
          }
          ... on WordPressAcf_content {
            content
          }
          ... on WordPressAcf_video {
            video
          }
          ... on WordPressAcf_blockquote {
            blockquote
          }
        }
      }
    }
  }
`

